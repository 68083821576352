import axios from 'axios';
import router from '../router'
import {tansParams} from './libMethods.js'
import {Message} from 'element-ui'
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API
axios.defaults.baseURL='https://zzbhzn.cn/api'
let falg=false;

axios.interceptors.request.use(config => {
  // config包含了发送给后端的相关请求，在config中可以设置请求头
  // 获取token
  if(config.getcode){
    delete config.getcode
    falg=false
  }else if(config.isLogin){
    delete config.isLogin
    falg=true
  }else{
    falg=false
    const token = localStorage.getItem('tokenAdmin')
    if (token) {
      config.headers.authorization = "Bearer " + token
    }
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
  }
  return config;
}, error => Promise.reject(error))


axios.interceptors.response.use(response => {
  // 验证response中res_code是否是401
  if(falg){
    if (response.data.code === 401) {
      localStorage.removeItem('token')
      // 跳转至登录
      router.push('/login')
    }
    return response.data
  }else{
    if(response.request.responseType ===  'blob' || response.request.responseType ===  'arraybuffer'){
      return response.data
    }
    if(response.data.code==200){
      return response.data;
    }else{
      if(response.data.code===401){
        localStorage.removeItem('token')
        // 跳转至登录
        router.push('/login') 
      }else if(response.data.code!=200){
        Message({
          message:response.data.msg,
          type:'error',
          duration:1000
        })
      }
    }
  }
}, error => Promise.reject(error))

export default axios